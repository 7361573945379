import React, {Fragment, ReactNode} from "react";
import {IProduct, TranslationsType} from "../types/homePageTypes";
import {GetServerSideProps, NextPage} from "next";
import Slider from "../components/ForCWV/Slider";
import Heading from "../components/ForCWV/Heading";
import Head from "next/head";
import MainLayout, {ICurrencies, ILayout} from "../components/ForCWV/Wrappers/MainLayout";
import {HomePageTranslations} from "../services/translationKeys";
import {layoutProps} from "../services/layoutProps";
import {domainUrl} from "../helper";
import BuilderSection from "../components/builder/BuilderDataRender";
import NewProducts from "../components/ForCWV/Products/BlockProduct/NewProducts";
import FeaturedProducts from "../components/ForCWV/Products/BlockProduct/FeaturedProducts";
import ThirdProducts from "../components/ForCWV/Products/BlockProduct/ThirdProducts";
import {getBuilderData, getHomeMetas, getHomeProducts, getSliderData} from "../api/pages/home";
import ReCaptchaWrapper from "../components/shared/ReCaptchaWrapper";

interface IHomePage extends ILayout {
    requestLogo: string;
    dbName: string;
    domain: string;
    sliderData: {
        sliders: Array<{
            path: string;
            title: string;
            content: string | TrustedHTML;
            settings: Record<`${number}`, { priority?: boolean; loading?: "eager" | "lazy" }>
            slider_path: string
        }>
    };
    newProducts: Array<IProduct>;
    featuredProducts: Array<IProduct>;
    thirdSectionProducts: Array<IProduct>;
    metas: {
        meta_description: string;
        meta_keywords: string;
        meta_title: string;
        share_pic: string;
    };
    translationsData: TranslationsType;
    backOrderValue: string;
    currencies: Array<ICurrencies>;
    selectedLocale: string;
    getBuilderData: any;
    showBuilderData: boolean;
    isMobile: boolean;
    showSlider: "Slider" | "Empty";
    showThirdSection: "ThirdSection" | "Empty";
    recaptchaActive: string;
    recaptchaKey: string
}

const Home: NextPage<IHomePage> = (pageProps) => {
    const {
        sliderData,
        newProducts,
        featuredProducts,
        thirdSectionProducts,
        metas: {
            meta_title = "",
            meta_description = "",
            meta_keywords = "",
            share_pic = "",
        },
        dbName,
        domain,
        translationsData,
        backOrderValue,
        currencies = [],
        selectedLocale = "",
        getBuilderData,
        showBuilderData,
        isMobile,
        showSlider,
        showThirdSection,
        recaptchaActive,
        recaptchaKey
    } = pageProps

    const [{
        code: selectedCurrCode = "",
        symbol: selectedCurrSymbol = "",
        exchange_rate: {rate: selectedCurrRate = "1"} = {},
    } = {}] = currencies

    const builderProps = {
        getBuilderData,
        domain,
        dbName,
        translationsData,
        selectedLocale,
        currencies,
        backOrderValue,
        selectedCurrItems: {selectedCurrCode, selectedCurrRate, selectedCurrSymbol},
        isMobile,
        recaptchaActive
    }

    const showBuilderComponents:
        (id: number) => Record<"true" | "false", ReactNode> = (id) => {
        return {
            "true": <BuilderSection tab={id} {...builderProps} />,
            "false": null
        }
    }
    const productProps = {
        selectedLocale,
        dbName,
        translationsData,
        backOrderValue,
        selectedCurrItems: {selectedCurrCode, selectedCurrRate, selectedCurrSymbol},
        isMobile
    }

    const ShowThirdSection = {
        "ThirdSection": <Fragment>
            <ThirdProducts products={thirdSectionProducts} {...productProps} />
            {showBuilderComponents(4)[`${showBuilderData}`]}
        </Fragment>,
        "Empty": null
    }
    const ShowSlider = {
        "Slider": <Slider slider={sliderData} dbName={dbName} translationsData={translationsData}/>,
        "Empty": null
    }

    return (
        <ReCaptchaWrapper recaptchaActive={recaptchaActive} recaptchaKey={recaptchaKey}>
            <MainLayout {...pageProps} >
                <Fragment>
                    <Head>
                        <title>{meta_title}</title>
                        <meta name="title" content={meta_title}/>
                        <meta name="description" content={meta_description}/>
                        <meta name="keywords" content={meta_keywords}/>
                        <meta name="generator" content="Zegashop.com"/>
                        <meta name="twitter:title" content={meta_title}/>
                        <meta name="twitter:description" content={meta_description}/>
                        <meta name="twitter:keywords" content={meta_keywords}/>
                        <meta name="twitter:image" content={domainUrl(`${dbName}/storage/${domain}${share_pic}`)}/>
                        <meta property="og:title" content={meta_title}/>
                        <meta property="og:description" content={meta_description}/>
                        <meta property="og:keywords" content={meta_keywords}/>
                        <meta property="og:image" content={domainUrl(`${dbName}/storage/${domain}${share_pic}`)}/>
                        <meta property="og:url" content={domainUrl(`${dbName}/${selectedLocale}`)}/>
                        <meta property="og:locale" content={selectedLocale}/>
                        <link rel="canonical" href={domainUrl(`${dbName}/${selectedLocale}`)}/>
                    </Head>
                    {ShowSlider[showSlider]}
                    <Heading translationsData={translationsData}/>
                    {showBuilderComponents(1)[`${showBuilderData}`]}
                    <NewProducts products={newProducts} {...productProps} />
                    {showBuilderComponents(2)[`${showBuilderData}`]}
                    <FeaturedProducts products={featuredProducts} {...productProps} />
                    {showBuilderComponents(3)[`${showBuilderData}`]}
                    {ShowThirdSection[showThirdSection]}
                </Fragment>
            </MainLayout>
        </ReCaptchaWrapper>
    );
};

export const getServerSideProps: GetServerSideProps = async ({locale, req}) => {
    const typedReq = req as unknown as {
        m: { [key: string]: any }[],
        defaultDB: { db_name: string },
        assets: { logo_image: string },
    }
    const wrapperProps = await layoutProps({
        requestHeaders: req.headers,
        requestDbName: typedReq.defaultDB.db_name,
        requestModels: typedReq.m,
        requestLogo: typedReq.assets.logo_image,
        nextJSLocale: locale,
        translationsObject: HomePageTranslations
    })
    const { suspendStatus, selectedLocale, isMobile, selectedRate } = wrapperProps;
    if (String(suspendStatus) === "469") {
        return {
            redirect: { destination: '/suspend', permanent: false },
        }
    }
    const metaTags = await getHomeMetas(selectedLocale, typedReq.m) || {};
    const {featuredProducts, newProducts, thirdSectionProducts} = await getHomeProducts(selectedLocale, selectedRate, isMobile, typedReq.m);
    const sliderData = await getSliderData(selectedLocale, isMobile, typedReq.m);
    const getBuilderDataServer = await getBuilderData(selectedLocale, req.headers["x-forwarded-host"], typedReq.defaultDB.db_name, selectedRate, typedReq.m) || []
    return {
        props: {
            requestLogo: typedReq.assets.logo_image,
            sliderData,
            metas: metaTags,
            newProducts,
            showSlider: !!sliderData.sliders?.length ? "Slider" : "Empty",
            showThirdSection: !!thirdSectionProducts?.length ? "ThirdSection" : "Empty",
            featuredProducts,
            thirdSectionProducts,
            activeMobileNavElement: 0,
            getBuilderData: getBuilderDataServer,
            showBuilderData: Object.values(getBuilderDataServer).some((lll) => (lll as Array<any>)?.length > 0),
            ...wrapperProps
        },
    };
}

export default Home;